<script>
import Confirmation from '@/components/Confirmation.vue'
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

export default {
  components: {
    Confirmation
  },

  apollo: {
    cultivars: {
      query: gql`
        query Customers {
          cultivars {
            id
            name
            others
          }
        }
      `,

      fetchPolicy: 'cache-and-network',
    },
  },

  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Cultivar',
          value: 'name',
        },
        {
          text: 'Outros nomes',
          value: 'others',
        },
        {
          text: 'Ações',
          value: '_actions',
          sortable: false,
          align: 'end',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  methods:{
    async remove(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid! $date: timestamptz) {
              update_cultivars_by_pk(
                pk_columns: { id: $id },
                _set: {
                  deleted_at: $date
                }
              ) {
                id
              }
            }
          `,
          variables: {
            id,
            date: new Date()
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.$apollo.queries.cultivars.refresh()
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<template>
  <div>
    <h1 class="mb-5">Espécies e cultivares</h1>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="cultivars"
        :search="search"
        :items-per-page="10"
        :loading="$apollo.loading"
        sort-by="name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn color="primary" dark to="/cultivares/new"> Novo </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item._actions`]="{ item }">
          <v-btn v-if="user.role !== 'manager'" text icon color="primary" :to="`/cultivares/${item.id}`">
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn v-if="user.role === 'manager'" text icon color="primary" :to="`/cultivares/${item.id}`">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <confirmation v-if="user.role === 'manager'" @success="() => remove(item.id)">
            <v-btn text icon color="error">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </confirmation>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
