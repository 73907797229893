<script>
export default {
  props: {
    message: {
      default() {
        return 'Tem certeza disso?'
      }
    },

    successText: {
      default() {
        return 'Sim'
      }
    },

    cancelText: {
      default() {
        return 'Não'
      }
    }
  },

  data (){
    return {
      menu: false
    }
  },

  methods: {
    cancel() {
      this.menu = false
      this.$emit('cancel')
    },

    confirm() {
      this.menu = false
      this.$emit('success')
    }
  }
}
</script>

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot />
      </span>
    </template>
    <v-card>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="cancel">
          {{ cancelText }}
        </v-btn>
        <v-btn color="primary" text @click="confirm">
          {{ successText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
